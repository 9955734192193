.mainHeader{
    /* background: radial-gradient(circle,rgb(5, 119, 30) 0%, rgb(17, 0, 8) 100%); */
    background: url('../../assets/resizedimg3prime.jpg')  no-repeat center center/cover;
    /* background-position: center; */
    padding: 0 0 18rem 0;
    
}

.headerContainer{
    color: var(--color-white);
    padding-top: 12rem;
    padding-bottom: 1rem;
    /* padding-bottom: 1rem; */
    /* padding-left: 1rem; */
    text-align: center;
    width: 60%;
}

.optimizeContainer h1{
    font-size: 1.8em;
    color: #85BC2D;
    font-weight: bold;
    margin-top: 2rem;
}

.optimizeContainer span{
    color: #85BC2D;
}

.scroll{
    /* position: absolute;
    top: 30rem; */
    width: 100%;
    /* top: 40rem; */
    /* background: #85BC2D; */
    overflow: hidden;
    
}

.scroll p{
    /* color: var(--color-primary-variant); */
    color: var(--color-white);
    font-size: 0.6em;
    /* padding-left: 1.3%; */
     width: 100%;
    letter-spacing: 0.1rem;
    padding: 0.3rem;
    text-align: center;
    /* transform: translateX(100%);
    animation: scrolling 30s linear infinite; */
}

/* @keyframes scrolling {
    0% {
        transform: translateX(100%);
    }

    100% {
        transform: translateX(-100%);
    }
} */



@media screen and (max-width: 1280px) {
    .mainHeader{
        padding: 3rem 0 24rem 0;
    }

    
  }

  @media screen and (max-width: 1024px) {
    .mainHeader{
        padding: 3rem 0 15rem 0;
    }

    .headerContainer{
        padding-top: 8rem;
        /* width: 70%; */
    }

    .optimizeContainer h1{
        /* font-size: 1.7em; */
    }
  }

  @media screen and (max-width: 912px) {
    .mainHeader{
        padding: 14rem 0 30rem 0;
    }
}

  @media screen and (max-width: 820px) {
    .mainHeader{
        padding: 9rem 0 24rem 0;
    }

    .headerContainer{
        /* width: 70%; */
    }  
}

@media screen and (max-width: 768px) {
    .mainHeader{
        padding: 5rem 0 18rem 0;
    }


    .headerContainer{
        width: 80%;
    }  
}

@media screen and (max-width: 600px) {
    .headerContainer{
        /* width: 100%; */
    }  
}
  @media screen and (max-width: 414px){
    .mainHeader{
        padding: 3rem 0 19rem 0;
    }

    .headerContainer{
        /* width: 70%; */
    }

    .optimizeContainer h1{
        font-size: 1.2em;
    }

    .headerContainer{
        /* padding-top: 8rem; */
    }

    .scroll p{
        /* width: 150%; */
    }
    
  }

  @media screen and (max-width: 400px) {
    .mainHeader{
        padding: 0rem 0 20rem 0;
    }

    .headerContainer{
        /* padding-top: 7rem; */
    }

    .optimizeContainer h1{
        /* font-size: 1.5em; */
    }
  }

  @media screen and (max-width: 300px) {
    .mainHeader{
        padding: 4rem 0 20rem 0;
    }

    .optimizeContainer{
        font-size: 0.8em;
    }

    .scroll p{
        /* width: 200%; */
    }
  }