.aboutContainer{
    margin-top: 5rem;
    width: 70%;
}

.abtContainer{
    color: var(--color-white);
    
}

.abtContainer h3{
    margin-bottom: 0.5rem;
    text-align: center;
    font-size: 1em;
    font-weight: 600;
    /* color: rgb(73, 72, 72); */
    color: #070800;
    padding-bottom: 2rem;
}

.evt{
    background: #85BC2D;
    border-radius: 2rem;
    padding: 5rem 0 5rem 0 ;
}

/* .evt:hover{
    background:#99d834;
    border: 1px#85BC2D solid;
    transition: 0.5s;
} */

.evt h4{
    font-size: 1em;
    margin-bottom: 0.8rem;
    color: var(--color-white);
    text-align: center;
} 

.evt_container{

    width: 85%;
    margin: 0 auto;
}


.evt_container p{
    font-size: 0.9em;
    /* width: 100%; */
    color: var(---color-white);
    text-align: justify;
}

@media screen and (max-width: 1024px) {
    .aboutContainer{
       /* margin-top: 10rem; */
    }
}

@media screen and (max-width: 768px) {
    .aboutContainer{
       /* margin-top: 20rem; */
    }
}

