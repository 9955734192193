.navMainContainer{
    /* background:  rgb(17, 0, 8); */
    background: black;
    position: sticky;
    top: 0;
    bottom: 0;
    z-index: 9999;
}

.navContainer{
    
    display: flex;
    justify-content: space-between; 
    align-content: center;
     align-items: top;
    /* color: aliceblue; */
    /* margin-top: 0.7rem; */
    padding-top: 0.2rem;
    padding-bottom: 0.2rem;
    /* background:  rgb(17, 0, 8); */
    background: black;
    z-index: 2;
    /* padding-left: 4.3rem; */
}

.navContainer img{
    width: 2.5rem;
}

.nav a{
    /* color: var(--color-white); */
    /* color: var(--color-primary-variant); */
    color: white;
    /* color: var(--color-bg); */
    /* background: var(--color-primary-variant); */
    /* background-color: #070800; */
    background: var(--color-bg-variant);
    border-radius: 0.3rem;
    padding: 0.5rem 1.2rem 0.5rem 1.2rem;
    font-size: 0.7em;
    margin-left: 3.5rem;
}

.nav a:hover{
    color: var(--color-primary-variant);
    background: #121402;
    transition: var(--transition);
}

.navBtn{
    cursor: pointer;
    background-color: transparent;
    border: none;
    outline: none;
    color: var(--color-primary-variant);
    font-size: 1.5rem;
    visibility: hidden;
    opacity: 0;
}

@media screen and (max-width: 900px) {
    .nav a{
        margin-left: 4rem;
    }
}

@media screen and (max-width: 768px) {
  
    .navContainer{
        /* display: block; */
        /* align-items: center; */
        width: 95%;
        z-index: 9999;
    }
    
    .navContainer img{
        width: 3rem;
    }
    
    .nav{
        position: fixed;
        top: 0;
        right: 0; 
        height: 100%;
        width: 70%;
        z-index: 2;
        padding-right: 3.5rem;
        display: flex;
        flex-direction: column;
        /* justify-content: space-around; */
        align-items: center;
        padding-top: 10rem;
        gap: 1rem;
        background: #070800;
        transition: 1s;
        transform: translateX(100vh);
    }

    .nav a{
        /* color: var(--color-primary-variant); */
        /* background: black; */
        padding: 1.2rem 4rem 1.2rem 4rem;
        margin-top: 1rem;
        border-radius: 0.5rem;
    }
    
    header .responsive{
      transform: none;
    }
    .navBtn{
        visibility: visible;
        opacity: 1;
        color: #85BC2D;
    }

    .navCloseBtn{
        position: absolute;
        top: 1rem;
        right: 1.5rem;
        font-size: 1rem;
    }
    
}
