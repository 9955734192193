.gallery{
    margin-top: 3rem;
    background: var(--color-bg-variant);
    border-top: 5px#85BC2D solid;
    border-bottom: 5px #85BC2D solid;
    height: 25%;
    padding-bottom: 2.5rem;
}

.galleryContainer{
    /* padding-bottom: 10rem; */
}

.galleryContainer h3{
    font-size: 1em;
    color: #85BC2D;
    text-align: center;
    padding-top: 3rem;
    padding-bottom: 1.5rem;  
}

.vidsContainer {
    display: grid;
    grid-template-columns: repeat(1fr, 5);
    justify-content: space-between;

}

.vidContainer{ 
    /* border: 1px var(--color-primary-variant) solid; */
    width: 100%; 
    position: relative;
    
}

/* .click{
    color: red;
    font-size: 100rem;
} */
video{
    /* width: 18.5%; */
    /* height: 10.5%; */
    width: 95%;
    border: 1px var(--color-primary-variant2) solid;
    border-radius: 0.4rem;
    /* margin-left: 5rem; */
}

.vidOverlay{
    color: var(--color-primary-variant2);
    font-size: 2rem;
    position: absolute;
    top: 40%;
    bottom: 0;
    right: 0;
    left: 40%;
    /* margin: 0 auto; */
    width: 100%;
    /* padding: 4.5rem 8rem 4.5rem 8rem; */
}

@media screen and (max-width: 1280px) {
    
}


@media screen and (max-width: 1024px) {
    /* .vidContainer {
     margin-right: 5rem;
    } */

    /* .video{
        width: 100%;
         } */
  }

  @media screen and (max-width: 768px) {
    .vidsContainer{
        gap: 5rem;
    }
  video{
    width: 95%;
    height: 95%;
  }  
  }

  @media screen and (max-width: 540px) {
    .vidContainer{
        /* padding-left: 2rem; */
    }    
     video{
        /* width: 98%;
        height: 98%; */
        /* height: 100%; */
   }

   .vidOverlay{
   }
  } 

@media screen and (max-width: 414px) {
    .galleryContainer h3{
        padding-top: 1.5rem;
    }

    .vidContainer{
        /* gap: 1rem; */
    }

    video{
    width: 100%;
    /* height: 100%;  */
    }

    .vidOverlay{
      top: 42%;  
      left: 45%;
    }
    
}