.eventContainer{
    background: black;
    height: 18rem;
    margin-bottom: 2rem;
    border-top: 5px #85BC2D solid;
    border-bottom: 5px #85BC2D solid;
}

.eventSnaps{
    /* display: grid; */
    /* grid-template-columns: repeat(9, 1fr);   */

    /* gap: 5rem; */
   
    /* justify-content: space-between;*/ 
    padding-top: 3.5rem; 
}

.snaps img{
    width: 96%;
    height: 10rem;
    border-radius: 0.5rem; 
}

@media screen and (max-width: 1024px) {
    .eventSnaps{
        grid-template-columns: repeat(3, 1fr);
    }
}

@media screen and (max-width: 768px) {
    .eventSnaps{
        grid-template-columns: repeat(2, 1fr);
    }
}

@media screen and (max-width: 414px) {
    .snaps img{
        width: 90%;
        margin: 0 auto;
    }
}

@media screen and (max-width: 400px) {
    .eventSnaps{
        grid-template-columns: repeat(1, 1fr);
    }
}