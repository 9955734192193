.contactMainContainer{
    background: rgb(73, 72, 72);
    border-top: 5px #85BC2D solid;
    padding-top: 3rem;
    margin-top: 5rem;
}

.container.contactContainer{
    /* margin-top: 4rem; */
    width: 70%;
}

.contactContainer h3{
    font-size: 1em;
    text-align: center;
    font-weight: 600;
    margin-bottom: 3rem;
    /* color: var(--color-white); */
    color: #85BC2D;
}

.contactAddress{
    display: flex;
    justify-content: space-between;
    gap: 2rem;
    align-items: center;
    /* gap: 1.5rem; */
    /* flex-direction: column; */
    margin-bottom: 5rem;
    width: 100%;
}

.contacts{
    /* background: white; */
    background: black;
    padding: 1.2rem;
    border-radius: 1.2rem;
    text-align: center;
    border: 1px solid #85BC2D;
    /* transition: var(--transition); */
    width: 30%;
    height: 100%;
    padding: 3rem 0 3rem 0;
    font-size: 1rem;
}

.contactsIcon{
    font-size: 1.4em;
    color: #85BC2D;
}

.contacts a{
    margin-top: 0.7rem;
    display: inline-block;
    font-size: 0.8rem;
    color: #85BC2D;

}

.post{
    margin-top: 1rem;
}

.mailLink{
    font-size: 1.4em;
}

/* .contacts p{
    font-size: 5rem;
} */

.subText{
    text-align: center;
    background: black;
    padding: 1.2rem;
    border-radius: 1.2rem;
    border: 1px solid #85BC2D;
    /* transition: var(--transition); */
    width: 30%;
    height: 100%;
    padding: 3rem 1rem 3rem 1rem;
    font-size: 1rem;
}

.subLink{
    color: #fff;
}
.contactImage img{
    width: 20%;
    display: block;
    margin-left: auto;
    margin-right: auto;
}

.contactImage{
    margin-bottom: 1rem;
}


.contactContainer p{
    color: #85BC2D;
    text-align: center;
    font-size: 0.8em;
    padding-bottom: 1rem;
}

@media screen and (max-width: 1024px) {
     .contactAddress{
        display: flex;
        gap: 1.5rem;
        flex-direction: column;
        margin-bottom: 5rem;   
        align-items: center;
    }

    .contacts{
        width: 100%;
        text-align: center;
    }

    .contactImage img{
        width: 30%;
    }

    .subText{
        width: 100%;
        text-align: center;
    }
}

@media screen and (max-width: 768px) {
    .container.contactContainer{
        /* margin-top: 4rem; */
        width: 80%;
    }
    
    .contactImage img{
        width: 50%;
    }
}

@media screen and (max-width: 360px) {
    .container.contactContainer{
        /* margin-top: 4rem; */
        width: 80%;
    }
    
}