.container.eventsContainer{
    width: 70%;
}

.eventsContainer{
    margin-top: 4rem;
    margin-bottom: 0;
}
.eventsContainer h3{
    /* color: var(--color-primary-variant); */
    color: #070800;
    /* color: grey; */
    /* color: rgb(73, 72, 72); */
    font-size: 1em;
    font-weight: 600;
    text-align: center;
    margin-bottom: 2rem;
}

.imgsContainer{
    display: flex;
    gap: 2rem;
    align-items: center;
}


.imgsContainer img{
    width: 100%;
    width: 70%;
    height: 90vh;
    object-position: center;
    object-fit: contain;
}

.imgsContainer .ctrlLink{
    background: #070800;
    padding: 1rem;
    border-radius: 0.5rem;
}

.imgsContainer a {
    color:#85BC2D;
    font-size: 0.8em;
    font-weight: bold;
    transition: all 0.5s ease;
}

.imgsContainer a:hover{
    color: grey;
}

.hennyContainer {
    font-size: 0.9em;
    background: #070800;
    color: #fff;
    /* color: #85BC2D; */
    /* background: #85BC2D;
    color: #070800; */
    padding: 2rem;
    border-radius: 2rem;
}

article{
    text-align: center;
}

.hennyLink{
    margin-top: 2rem;
    text-align: center;
}

.hennyLink a{
    color: #fff;
    background: #85BC2D;
    padding: 0.5rem 1rem 0.5rem 1rem;
    border-radius: 0.3rem;
}

.hennyLink a:hover{
    color: #85BC2D;
    background: #fff;
    transition: ease 0.5s;
}

@media screen and (max-width: 1280px) {
    .imgsContainer img{
        width: 80%;
        height: 50vh;
    }

    
}

@media screen and (max-width: 1024px) {
    .imgsContainer{
        display: block;
    }

    .imgsContainer img{
        width: 100%;
        height: 1%;
    }

    .hennyContainer{
        width: 100%;
        margin: 0 auto;
        margin-top: 1rem;
    }

    .hennyContainer p{
        text-align: justify;
    }
}

@media screen and (max-width: 768px) {
    .imgsContainer{
        grid-template-columns: repeat(2, 1fr);
    }
}

@media screen and (max-width: 400px) {
    .imgsContainer{
        grid-template-columns: repeat(1, 1fr);
    }
}

