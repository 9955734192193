
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  border: 0;
  outline: 0;
  list-style: none;
  text-decoration: none;
}

:root {
  --color-bg: #050505;
  --color-bg-variant:  #0e0f06;
  --color-primary: #09aa32;
  --color-primary-variant: #cfec67;
  --color-primary-variant2: #506406;
  --color-white: #fff;
  --transition: all 400ms ease;
  --container-width-lg: 90%;
  --container-width-md: 90%;
  --container-width-sm: 95%;
  --transition: all 500ms ease;
}

html {
  scroll-behavior: smooth;
}

::-webkit-scrollbar {
  display: none;
}


body {
  /* font-family: sans-serif; */
  font-family:'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  /* background: radial-gradient(circle, #cfec67 20%, rgb(17, 0, 8) 80%); */
  /* background: radial-gradient(circle,rgb(0, 29, 7) 20%, rgb(17, 0, 8) 80%); */
  background: white;
  
}


.container {
  width: var(--container-width-lg);
  margin: 0 auto;

}

@media screen and (max-width: 1024px) {
  .container{
    width: var(--container-width-md);
  }
}

@media screen and (max-width: 600px) {
.container{
  width:var(--container-width-sm)
}
}
  
