.container.aboutUsContainer{
    margin-top: 4rem;
    width: 70%;
    /* background: var(--color-bg); */
    border: 1px #85BC2D solid;
    border-radius: 2rem;
    padding: 3rem 3rem 4rem 3rem;
    /* background: var(--color-bg-variant); */
}

.aboutUsContainer h3{
    font-size: 1em;
    /* color: grey; */
    color: #85BC2D;
    margin-top: 1rem;
    margin-bottom: 1rem;
    text-align: center;
}

.aboutUsContainer p{
    font-size: 0.9em;
    text-align: justify;
    /* color: rgb(73, 72, 72); */
    color: #85BC2D;
}