.container.promotionContainer{
    color: var(--color-white);
    margin-top: 3rem;
    color: var(--color-bg-variant);
    width: 70%;
    padding: 2rem;
    background: #070800;
    color: #85BC2D;
    border-radius: 2rem;
    /* padding: 5rem; */
}

.promotionContainer h4{
    font-size: 1em;
    text-align: center;
    margin-bottom: 0.5rem;
    padding-top: 3rem;
}

.promotionText{
    width: 95%;
    margin: 0 auto;
}



.promotionContainer p{
    font-size: 0.9em;
    text-align: justify;
    padding-bottom: 3rem;
    /* width: 85%; */
}